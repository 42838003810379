@font-face {
  font-family: BrownLLWeb;
  src: url('./brown/BrownLLWeb-Light.woff') format('woff'), url('./brown/BrownLLWeb-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: BrownLLWeb;
  src: url('./brown/BrownLLWeb-LightItalic.woff') format('woff'),
    url('./brown/BrownLLWeb-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: BrownLLWeb;
  src: url('./brown/BrownLLWeb-Regular.woff') format('woff'), url('./brown/BrownLLWeb-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: BrownLLWeb;
  src: url('./brown/BrownLLWeb-Italic.woff') format('woff'), url('./brown/BrownLLWeb-Italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: BrownLLWeb;
  src: url('./brown/BrownLLWeb-Medium.woff') format('woff'), url('./brown/BrownLLWeb-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: BrownLLWeb;
  src: url('./brown/BrownLLWeb-MediumItalic.woff') format('woff'),
    url('./brown/BrownLLWeb-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: BrownLLWeb;
  src: url('./brown/BrownLLWeb-Bold.woff') format('woff'), url('./brown/BrownLLWeb-Bold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: BrownLLWeb;
  src: url('./brown/BrownLLWeb-BoldItalic.woff') format('woff'),
    url('./brown/BrownLLWeb-BoldItalic.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: BrownLLWeb;
  src: url('./brown/BrownLLWeb-Black.woff') format('woff'), url('./brown/BrownLLWeb-Black.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: BrownLLWeb;
  src: url('./brown/BrownLLWeb-BlackItalic.woff') format('woff'),
    url('./brown/BrownLLWeb-BlackItalic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
}
