/* Import the normalize.css library to reset browser styles */
@import '~normalize.css/normalize.css';

body {
  overflow: auto;
  overflow-x: hidden;
  width: 100vw;
  max-height: 100vh;
  font-family: var(--seccl-primary-font);
}

/*  NPROGRESS */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: var(--seccl-color-brand-secondary);
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

a {
  color: var(--seccl-color-accent);
  text-decoration: none;
}
